import React from 'react'

function Contact2() {
  return (
    <section class="contact_form_section">
    <div class="container">
        <div class="contact_inner">
            <div class="contact_form">
              <div class="section_title">
                  <h2>Leave a <span>message</span></h2>
                  <p>Fill up form below, our team will get back soon</p>
              </div>
                <form action="submit">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" placeholder="Name" class="form-control"/>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="email" placeholder="Email" class="form-control"/>
                      </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" placeholder="Company Name" class="form-control"/>
                      </div>
                  </div>

                  <div class="col-md-6">
                      <div class="form-group">
                          <select class="form-control">
                            <option value="">Country</option>
                          </select>
                      </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" placeholder="Phone" class="form-control"/>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" placeholder="Website" class="form-control"/>
                      </div>
                  </div>
              </div>

                  <div class="form-group">
                      <textarea class="form-control" placeholder="Your message"></textarea>
                  </div>

                  <div class="form-group term_check">
                    <input type="checkbox" id="term"/>
                    <label for="term">I agree to receive emails, newsletters and promotional messages</label>
                  </div>

                  <div class="form-group ">
                    <button class="btn btn_main" type="submit">SEND MESSAGE <i class="icofont-arrow-right"></i></button>
                </div>

                </form>

                <div class="form-graphic">
                  <img src="./assets/images/paperplane.png" alt="image"/>
                </div>
            </div>
            
            
        </div>
    </div>
  </section>
  )
}

export default Contact2