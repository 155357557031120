import React from 'react'

function AuthTextBox({children,placeholder,name,value,changed,type='text'}) {
  return (
    <div className="form-group">
    <input type={type} className="form-control" name={name} value={value} onChange={changed} placeholder={placeholder} />
    {children}
  </div>
  )
}

export default AuthTextBox