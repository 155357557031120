import React, { useEffect, useState, useRef } from 'react'
import API from "../../../../modules/api";
import * as appConsts from '../../../../store/consts'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang, addChangeTranslateArr } from '../../../../store/pathActions'
import Util from "../../../../modules/utils";
import './HomeAnalyze.css'
function HomeAnalyze() {

    const utl = new Util();

    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const [showElement, setShowElement] = useState(true);
    const [homeAnalyzeState, setHomeAnalyzeState] = useState(
        {
            image1_url: "/assets/images/analyze-data-01.png",
            image2_url: "/assets/images/analyze-data-02.png",
            image3_url: "/assets/images/analyze-data-03.png",


            title1: "Analyze your data with powerful tool",
            dsc1: "Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.",
            title2: "Carefully designed",
            dsc2: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has",
            title3: "Seamless Sync",
            dsc3: "Simply dummy text of the printing and typesetting inustry lorem Ipsum has Lorem dollar summit.",


        }
    );




    useEffect(() => {
        loadData();



    }, [lang,tranlates])


    const loadData = () => {

        let arrNames = ['home-analyze-show'];
        let arrImges = [];
        let arrTexts = [];

        for (let index = 1; index <= 3; index++) {
            arrNames = [...arrNames, `home-analyze-image${index}-attach-id`];
            arrImges = [...arrImges, `home-analyze-image${index}-attach-id`];
            const arr = ['title', 'dsc'];
            for (let index2 = 0; index2 < arr.length; index2++) {
                arrNames = [...arrNames, `home-analyze-${arr[index2]}${index}`];
                arrTexts = [...arrTexts, `home-analyze-${arr[index2]}${index}`]

            }

        }
        let obj = {};
        try {
            tranlates.then(result2 => {
                (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                    //console.log("TRE", result)
                    if (result['home-analyze-show'] != null) {
                        setShowElement((result['home-analyze-show'] == 'true'))
                    }
                    for (let index = 0; index < arrImges.length; index++) {
                        const element = arrImges[index];
                        const elementName = element.replace('home-analyze-', '').replace('-attach-id');
                        if (result[element] != null)
                            obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

                    }
                    for (let index = 0; index < arrTexts.length; index++) {
                        const element = arrTexts[index];
                        const elementName = element.replace('home-analyze-', '');
                        if (result[element] != null)
                            obj[elementName] = utl.translateWord(result2,result[element]);

                    }


                    setHomeAnalyzeState({ ...homeAnalyzeState, ...obj });
                });
            });

        } catch (error) {

        }



    }





    return (
        <section className={`row_am analyze_section ${showElement ? '' : 'd-none'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="analyze_text aos-init" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                            <span className="icon"><img src="/assets/images/Analyze_Icon.svg" alt="image" /></span>
                            <div className="section_title">
                                <h2>{homeAnalyzeState.title1}</h2>
                                <p>{homeAnalyzeState.dsc1}</p>
                            </div>
                            <ul>
                            {
                        [...Array(2)].map((x, i) => {
                           return <li data-aos="fade-up" data-aos-duration="2000" className="aos-init">
                            <h3>{homeAnalyzeState[`title${i+2}`]}</h3>
                            <p>{homeAnalyzeState[`dsc${i+2}`]}</p>
                        </li>
                     
                        })
                    }
                              
                              
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="analyze_image aos-init" data-aos="fade-in" data-aos-duration="1000">
                        {
                        [...Array(3)].map((x, i) => {
                          return  <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className="moving_animation aos-init" src={homeAnalyzeState[`image${i+1}_url`]} alt="image" />


                        })

                      }
                                                   </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeAnalyze