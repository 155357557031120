import React from 'react'

function GoTop() {
  return (
    <div className="go_top" id="Gotop">
    <span><i className="icofont-arrow-up"></i></span>
</div>
  )
}

export default GoTop