import React, { useEffect, useState, useRef } from 'react'
import API from "../../../../modules/api";
import * as appConsts from '../../../../store/consts'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang, addChangeTranslateArr } from '../../../../store/pathActions'
import './HomeCollaborate.css'
import Util from "../../../../modules/utils";
function HomeCollaborate() {



    const utl = new Util();

    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const [showElement, setShowElement] = useState(true);

    const [homeCollaborateState, setHomeCollaborateState] = useState(
        {
            icon1_url: "/assets/images/Collaborate-icon_1.png",
            image1_url: "/assets/images/Collaborate-01.png",
            icon2_url: "/assets/images/Collaborate-icon_2.png",
            image2_url: "/assets/images/Collaborate-02.png",
            image_secure_url: "/assets/images/securely.svg",


            title: "Collaborate securely everything",
            dsc: "Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.",



            title1: "Sync followers",
            dsc1: "Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.",
            title2: "Globally connected",
            dsc2: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has",


            link_title: "READ MORE",
            link_url: "/",


        }
    );




    useEffect(() => {
        loadData();



    }, [])

    useEffect(() => {
        loadData();



    }, [lang,tranlates])


    const loadData = () => {

        let arrNames = ['home-collaborate-show'];
        let arrImges = [];
        let arrTexts = [];
        let arrStatics = ["home-collaborate-link_title", "home-collaborate-link_url", 'home-collaborate-title', 'home-collaborate-dsc'];
        arrNames = [...arrNames, ...arrStatics];
        for (let index = 1; index <= 2; index++) {
            arrNames = [...arrNames, `home-collaborate-icon${index}-attach-id`];
            arrNames = [...arrNames, `home-collaborate-image${index}-attach-id`];
            arrImges = [...arrImges, `home-collaborate-image${index}-attach-id`];
            arrImges = [...arrImges, `home-collaborate-icon${index}-attach-id`];
            const arr = ['title', 'dsc'];
            for (let index2 = 0; index2 < arr.length; index2++) {
                arrNames = [...arrNames, `home-collaborate-${arr[index2]}${index}`];
                arrTexts = [...arrTexts, `home-collaborate-${arr[index2]}${index}`]

            }

            arrNames = [...arrNames, `home-collaborate-image-secure-attach-id`];
            arrImges = [...arrImges, `home-collaborate-image-secure-attach-id`];

        }
        let obj = {};
        try {
            tranlates.then(result2 => {
                (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                  //  console.log("TRE", result)
                    if (result['home-collaborate-show'] != null) {
                        setShowElement((result['home-collaborate-show'] == 'true'))
                    }
                    for (let index = 0; index < arrImges.length; index++) {
                        const element = arrImges[index];
                        if (element == "home-collaborate-image-secure-attach-id") {
                            if (result[element] != null)
                                obj['image_secure_url'] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
                        } else {
                            const elementName = element.replace('home-collaborate-', '').replace('-attach-id');
                            if (result[element] != null)
                                obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
                        }


                    }
                    for (let index = 0; index < arrTexts.length; index++) {
                        const element = arrTexts[index];
                        const elementName = element.replace('home-collaborate-', '');
                        if (result[element] != null)
                            obj[elementName] = utl.translateWord(result2,result[element])

                    }
                    for (let index = 0; index < arrStatics.length; index++) {

                        const element = arrStatics[index];

                        const elementName = element.replace('home-collaborate-', '');
                        if (result[element] != null)
                            obj[elementName] = utl.translateWord(result2,result[element]);

                    }
                    console.log("WERTY", obj)
                    setHomeCollaborateState({ ...homeCollaborateState, ...obj });
                });
            });

        } catch (error) {
           
            console.log("ERR", error)
        }



    }



    return (
        <section className={`row_am collaborate_section ${showElement ? '' : 'd-none'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="collaborate_image aos-init" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                            <div className="img_block aos-init" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                                <img className="icon_img moving_animation" src={homeCollaborateState.icon1_url} alt="image" />
                                <img src={homeCollaborateState.image1_url} alt="image" />
                            </div>
                            <div className="img_block aos-init" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                                <img src={homeCollaborateState.image2_url} alt="image" />
                                <img className="icon_img moving_animation" src={homeCollaborateState.icon2_url} alt="image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="collaborate_text aos-init" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                            <span className="icon"><img src={homeCollaborateState.image_secure_url} alt="image" /></span>
                            <div className="section_title">
                                <h2>{homeCollaborateState.title}</h2>
                                <p>{homeCollaborateState.dsc}</p>
                            </div>
                            <ul>
                                <li data-aos="fade-up" data-aos-duration="2000" className="aos-init">
                                    <h3>{homeCollaborateState.title1}</h3>
                                    <p>{homeCollaborateState.dsc1}</p>
                                </li>
                                <li data-aos="fade-up" data-aos-duration="2000" className="aos-init">
                                <h3>{homeCollaborateState.title2}</h3>
                                <p>{homeCollaborateState.dsc2}</p>
                                </li>
                            </ul>
                            <a href={homeCollaborateState.link_url} data-aos="fade-up" data-aos-duration="2000" className="btn btn_main aos-init">{homeCollaborateState.link_title} <i className="icofont-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HomeCollaborate