import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import API from "../../../../modules/api";
import * as appConsts from '../../../../store/consts'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang, addChangeTranslateArr } from '../../../../store/pathActions'
import Util from "../../../../modules/utils";
import './HomeFAQ.css'
function HomeFAQ() {
    const utl = new Util();

    const path = useSelector((state) => state.path)
    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const [isChangeState, setIsChangeState] = useState(false);
    const [showElement, setShowElement] = useState(true);
    const [homeState, setHomeState] = useState({
        title: "سوالات متداول",
        dsc: "سوالات متداول مطرح شده",
        faqs: []


    })
    useEffect(() => {
     //   alert('')
        loadData();
       


    }, [])
    useEffect(() => {
       // alert('')
        loadData();


    }, [path])

    const loadData = () => {
        (new API()).sendGETWithoutToken(`/api/settings/name/fq`, (result) => {

            try {
                if (result != null)
                    setHomeState({ ...homeState, faqs: [...JSON.parse(result.val)] })
                setIsChangeState(true)
              //  fillData();
            } catch (error) {
//alert('err')
            }

        })

    }



    const fillData = () => {
        try {


            tranlates.then(result2 => {
                const title = utl.translateWord(result2, homeState.title);
                const dsc = utl.translateWord(result2, homeState.dsc);
                let temp = [...homeState.faqs]
                temp.forEach((item, index) => {

                    item.question = utl.translateWord(result2, item.question);
                    item.answer = utl.translateWord(result2, item.answer);


                })

                console.log('first', temp)
                setHomeState({ title: title, dsc: dsc, faqs: [...temp] })
                setIsChangeState(false)
            });

        } catch (error) {

        }
    }
   const t = useMemo(() => {
   
       // fillData();
       loadData();

    }, [lang, tranlates])

    const v = useMemo(() => {
   
        if(isChangeState){

            fillData();
            setIsChangeState(false);
        }
     //  loadData();

    }, [homeState,isChangeState])

    return (
        <section id="faqBlock" className="row_am faq_section">

            <div className="container">
                <div className="section_title aos-init" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">

                    <h2>{homeState.title}</h2>

                    <p>{homeState.dsc}.{homeState.faqs.length}</p>
                </div>

                <div className="faq_panel">
                    <div className="accordion" id="accordionExample">

                        {homeState.faqs.map((item, index) => {
                            return <div className="card aos-init" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button type="button" className="btn btn-link" data-toggle="collapse" data-target={`#collapseOne${index}`}>
                                            <i className="icon_faq icofont-minus"></i> {item.question}</button>
                                    </h2>
                                </div>
                                <div id={`collapseOne${index}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                        {item.answer}

                                    </div>
                                </div>
                            </div>
                        })}
                        {/* <div className="card aos-init" data-aos="fade-up" data-aos-duration="1500">
                    <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                            <button type="button" className="btn btn-link active" data-toggle="collapse" data-target="#collapseOne">
                                <i className="icon_faq icofont-minus"></i> How can i pay ?</button>
                        </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has. been the
                                industrys standard dummy text ever since the when an unknown printer took a galley of type and
                                scrambled it to make a type specimen book. It has survived not only five cen turies but also the
                                leap into electronic typesetting, remaining essentially unchanged.</p>
                        </div>
                    </div>
                </div>
                <div className="card aos-init" data-aos="fade-up" data-aos-duration="1500">
                    <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                            <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"><i className="icon_faq icofont-plus"></i> How to setup account ?</button>
                        </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has. been the
                                industrys standard dummy text ever since the when an unknown printer took a galley of type and
                                scrambled it to make a type specimen book. It has survived not only five cen turies but also the
                                leap into electronic typesetting, remaining essentially unchanged.</p>
                        </div>
                    </div>
                </div>
                <div className="card aos-init" data-aos="fade-up" data-aos-duration="1500">
                    <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                            <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"><i className="icon_faq icofont-plus"></i>What is process to get refund
                                ?</button>
                        </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div className="card-body">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has. been the
                                industrys standard dummy text ever since the when an unknown printer took a galley of type and
                                scrambled it to make a type specimen book. It has survived not only five cen turies but also the
                                leap into electronic typesetting, remaining essentially unchanged.</p>
                        </div>
                    </div>
                </div>
                <div className="card aos-init" data-aos="fade-up" data-aos-duration="1500">
                    <div className="card-header" id="headingFour">
                        <h2 className="mb-0">
                            <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour"><i className="icon_faq icofont-plus"></i>What is process to get refund
                                ?</button>
                        </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                        <div className="card-body">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has. been the
                                industrys standard dummy text ever since the when an unknown printer took a galley of type and
                                scrambled it to make a type specimen book. It has survived not only five cen turies but also the
                                leap into electronic typesetting, remaining essentially unchanged.</p>
                        </div>
                    </div>
                </div> */}
                    </div>
                </div>
            </div>

        </section>
    )
}

export default HomeFAQ