import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { createStore,applyMiddleware } from 'redux'
import {thunk} from 'redux-thunk'
import { Provider } from 'react-redux'

import App from './App';
import reportWebVitals from './reportWebVitals';
import reducer from './store/reducer'
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css'
const inistialState = {
  path: "/",
  lang: (window.localStorage.getItem("lang")?window.localStorage.getItem("lang"):"fa"),
  result: [],
}
const middleware = [thunk]
const store = createStore(
  reducer,inistialState, applyMiddleware(...middleware),
 // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const clientId = '318604159055-9snfd38sg29o1gcrrk7823tea15pr3bs.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
    <App />
  </GoogleOAuthProvider>,
  </React.StrictMode>
  </Provider>,

 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
