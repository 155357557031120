import React, { useEffect, useState } from 'react'
import { useSearchParams, useParams, Link } from "react-router-dom";
import Utils from "../../../modules/utils";
import { useDispatch, useSelector } from 'react-redux'
import { addChangeView,addChangePageTitle,addChangeBasket,addChangeLlogin } from '../../../store/pathActions'
import API from "../../../modules/api";
import RouteLink from '../../../components/Routing/RouteLink';
export default function RialZibal() {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();
    const [payState, setPayState] = useState({
        success: -1,
        trackId: null,
        orderId: null,
        id: null,


    });
    const [myState, setMyState] = useState({
        type: "warning",
        message: "در حال تکمیل فرآیند خرید ...",
        link: "/products"



    });
    useEffect(() => {

        if (id) {


            if (searchParams.get('success') == "1"
                && searchParams.get('trackId')
                && searchParams.get('orderId')
                && searchParams.get('orderId') == id) {
                setPayState({
                    success: parseInt(searchParams.get('success')),
                    trackId: searchParams.get('trackId'),
                    orderId: id
                })
            } else {

            }
        }
        console.log(searchParams.get('success'));
    }, [])

    useEffect(() => {

        if (payState.success == "1") {

            (new API()).sendGETWithToken(`/api/payments/verify/${payState.trackId}`, function (result) {

                console.log("verify", result)
                if (result.success) {
                    (new API()).sendGETWithToken(`/api/payments/inquiry/${payState.trackId}`, function (result2) {
                        if (result2.success) {
                            setMyState({
                                type: "success",
                                message: "خرید و پرداخت موفقیت آمیز انجام شد",
                                link: "/products"



                            })
                            localStorage.removeItem("basket");
                            dispatch(addChangeBasket(0))
                        } else {
                            setMyState({
                                type: "danger",
                                message: "خرید و پرداخت موفقیت آمیز نبود",
                                link: "/shopping-card"



                            })
                        }

                        console.log("verifyrrrr", result)
                    }, function (err) {
                        console.log("verifyiii2", err)
                    }, function (err2) {
                        setMyState({
                            type: "danger",
                            message: "خرید و پرداخت موفقیت آمیز نبود",
                            link: "/shopping-card"



                        })
                    })

                } else {
                    setMyState({
                        type: "danger",
                        message: "خرید و پرداخت موفقیت آمیز نبود",
                        link: "/shopping-card"



                    })
                }


            }, function (err) {
                setMyState({
                    type: "danger",
                    message: "خرید و پرداخت موفقیت آمیز نبود",
                    link: "/shopping-card"



                })
                console.log("verify2", err)
            })
        } else {
            setMyState({
                type: "danger",
                message: "خرید و پرداخت موفقیت آمیز نبود",
                link: "/shopping-card"



            })
        }
    }, [payState])
    return (
        <>
            <div className='p-5'>

                <div className={`text-center alert alert-${myState.type}`} role="alert">
                    {myState.message}

                    {myState.type != "warning" ? <RouteLink href={myState.link} className="alert-link">بازگشت</RouteLink> : null}
                </div>
            </div>

        </>
    )
}
