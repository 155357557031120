import React, { useEffect, useState, useRef } from 'react'
import API from "../../../../modules/api";
import * as appConsts from '../../../../store/consts'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang, addChangeTranslateArr } from '../../../../store/pathActions'
import Util from "../../../../modules/utils";
import './HomeProducts.css'
import { Link } from 'react-router-dom';
function HomeProducts() {
    const utl = new Util();

    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const [showElement, setShowElement] = useState(true);
    const [showPrice, setShowPrice] = useState(true);
    const [homeState, setHomeState] = useState(
        {
            products: [],
            productsInfo: [],
            image1_url: "/assets/images/Free-Trial-01.svg",
            image2_url: "/assets/images/unlimited.png",
            image3_url: "/assets/images/Premium.svg",


            title: "Best plans, pay what you use",
            dsc: "Lorem Ipsum is simply dummy text of the printing and typese tting indus lorem Ipsum has beenthe standard dummy text ever since.",

            btn_title: "BUY NOW",
            day_trans:"روز"


        }
    );
    const loadProducts = () => {

        try {
            (new API()).sendGETWithoutToken("/api/products-packages", (result) => {


                console.log("TUERT", result)
                loadData(function (reProducts, obj,result2) {


                    let packages = [];
                    for (let index = 0; index < reProducts.split(',').length; index++) {
                        const element = reProducts.split(',')[index];
                        const temp = result.filter((item) => {
                            const pkgs = item.packages.filter((itemb) => {

                                if (element == itemb.id) {

                                    itemb.name=utl.translateWord(result2,itemb.name);
                                    if(itemb.details!=null){
                                        itemb.details.map((detail)=>{
                                            detail.name=utl.translateWord(result2,detail.name);
                                            detail.val=utl.translateWord(result2,detail.val);
                                        });
                                    }
                                    packages = [...packages, { productName: utl.translateWord(result2,item.name), ...itemb }]
                                    return true
                                }
                                return false;

                            })


                            return pkgs.length > 0
                        });



                    }

                    console.log("ABCDE", packages)

                    setHomeState({ ...homeState, ...obj, products: reProducts.split(','), productsInfo: packages });



                })

            })





        } catch (error) {

        }


    }



    const loadData = (funcProducts) => {

        let arrNames = ['home-products-show','home-products-price-show'];
        let arrImges = [];
        let arrTexts = [];
        let arrStatics = ['home-products-day_trans'];
        arrNames = [...arrNames, ...arrStatics];
        for (let index = 1; index <= 3; index++) {
            arrNames = [...arrNames, `home-product-image${index}-attach-id`];
            arrImges = [...arrImges, `home-product-image${index}-attach-id`];

        }

        const arr = ['title', 'dsc', 'btn_title'];
        for (let index2 = 0; index2 < arr.length; index2++) {

            //console.log("POPOP", `home-product-${arr[index2]}`);
            arrNames = [...arrNames, `home-product-${arr[index2]}`];
            arrTexts = [...arrTexts, `home-product-${arr[index2]}`]

        }
        arrNames = [...arrNames, "home-products"];

        let obj = {};
        try {
            tranlates.then(result2 => {
                (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {

                   // console.log("TRE", result);

                    if (result['home-products-show'] != null) {
                        setShowElement((result['home-products-show'] == 'true'))
                    }
                    if (result['home-products-price-show'] != null) {
                        setShowPrice((result['home-products-price-show'] == 'true'))
                    }
                    for (let index = 0; index < arrImges.length; index++) {
                        const element = arrImges[index];
                        const elementName = element.replace('home-product-', '').replace('-attach-id');
                        if (result[element] != null)
                            obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

                    }
                    for (let index = 0; index < arrTexts.length; index++) {
                        const element = arrTexts[index];
                        const elementName = element.replace('home-product-', '');
                        if (result[element] != null)

                            obj[elementName] = utl.translateWord(result2,result[element]);

                    }

                    // for (let index = 0; index < arrStatics.length; index++) {

                    //     const element = arrStatics[index];
    
                    //     const elementName = element.replace('home-products-', '');
                    //     console.log("elementName",elementName);
                    //     if (result[element] != null)
                    //         obj[elementName] = utl.translateWord(result2,result[element]);
                      

                    // }
                    console.log("arrStatics",arrStatics);

                    console.log("OBJ",obj);
                    obj["day_trans"] = utl.translateWord(result2,homeState["day_trans"]);
                    if (result["home-products"] != null) {


                        funcProducts(result["home-products"], obj,result2)
                    }

                });
            });

        } catch (error) {

        }



    }

    useEffect(() => {
        loadProducts();
    }, [])

    useEffect(() => {
        loadProducts();
    }, [lang,tranlates])




    return (
        <section className="row_am pricing_section aos-init" id="pricing" data-aos="fade-in" data-aos-duration="1000">

            <div className="container">
                <div className="section_title aos-init" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h2>{homeState.title}</h2>
                    <p>
                        {homeState.dsc}
                    </p>
                </div>



                <div className="pricing_pannel monthly_plan active aos-init" data-aos="fade-up" data-aos-duration="1500">

                    <div className="row">

                        {
                            [...Array(homeState.productsInfo.length)].map((x, i) => {
                                return <div className="col-md-4">
                                    <div className="pricing_block">
                                        <div className="icon">
                                            <img src={homeState[`image${i + 1}_url`]} alt="image" />

                                            <div className="dot_block">
                                                <span className="dot_anim"></span>
                                                <span className="dot_anim"></span>
                                                <span className="dot_anim"></span>
                                            </div>
                                        </div>
                                        <div className="pkg_name">
                                            <h3>{homeState.productsInfo[i].productName}</h3>
                                            <span>{homeState.productsInfo[i].name}</span>
                                            <h3>{homeState.productsInfo[i].day_count} {homeState.day_trans}</h3>
                                        </div>
                                        <span className={`price ${showPrice?'':'d-none'}`}>${homeState.productsInfo[i].dollar_price}<span></span></span>
                                        <ul className="benifits">

                                            {
                                                [...Array(homeState.productsInfo[i].details.length)].map((x2, i2) => {
                                                    return (homeState.productsInfo[i].details[i2].val=='[OK]' ||  homeState.productsInfo[i].details[i2].val=='[NO]'? <li className={`${homeState.productsInfo[i].details[i2].val == "[OK]" ? "include" : "exclude"}`}>
                                                        <p><i className={`icofont-${homeState.productsInfo[i].details[i2].val == "[OK]" ? "check" : "close"}-circled`}></i>{homeState.productsInfo[i].details[i2].name}</p>
                                                    </li>:null)
                                                })
                                            }

                                        </ul>
                                        <Link to={`/product/${homeState.productsInfo[i].id}`} className="btn btn_main"> {homeState.btn_title} <i className="icofont-arrow-right"></i></Link>
                                      
                                    </div>
                                </div>

                            })
                        }

                    </div>

                </div>

                <div className="pricing_pannel yearly_plan aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                    <div className="row">


                        <div className="col-md-4">
                            <div className="pricing_block">
                                <div className="icon">
                                    <img src="/assets/images/Free-Trial-01.svg" alt="image" />
                                    <div className="dot_block">
                                        <span className="dot_anim"></span>
                                        <span className="dot_anim"></span>
                                        <span className="dot_anim"></span>
                                    </div>
                                </div>
                                <div className="pkg_name">
                                    <h3>Free Trial</h3>
                                    <span>For the basics</span>
                                </div>
                                <span className="price">$0<span>/Year</span></span>
                                <ul className="benifits">
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>7 Days free trial</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>2 platform of your choice</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>
                                    </li>
                                    <li className="exclude">
                                        <p><i className="icofont-close-circled"></i>Unlimited updates</p>
                                    </li>
                                    <li className="exclude">
                                        <p><i className="icofont-close-circled"></i>Live support</p>
                                    </li>
                                </ul>
                                <a href="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></a>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="pricing_block highlited_block">
                                <div className="icon">
                                    <img src="/assets/images/unlimited.png" alt="image" />
                                    <div className="dot_block">
                                        <span className="dot_anim"></span>
                                        <span className="dot_anim"></span>
                                        <span className="dot_anim"></span>
                                    </div>
                                </div>
                                <div className="pkg_name">
                                    <h3>Unlimited</h3>
                                    <span>For the professionals</span>
                                </div>
                                <span className="price">$999<span>/Year</span></span>
                                <ul className="benifits">
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>7 Days free trial</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>2 platform of your choice</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>Unlimited updates</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>Live support</p>
                                    </li>
                                </ul>
                                <a href="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></a>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="pricing_block">
                                <div className="icon">
                                    <img src="/assets/images/Premium.svg" alt="image" />
                                    <div className="dot_block">
                                        <span className="dot_anim"></span>
                                        <span className="dot_anim"></span>
                                        <span className="dot_anim"></span>
                                    </div>
                                </div>
                                <div className="pkg_name">
                                    <h3>Premium</h3>
                                    <span>For small team</span>
                                </div>
                                <span className="price">$555<span>/Year</span></span>
                                <ul className="benifits">
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>7 Days free trial</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>2 platform of your choice</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>
                                    </li>
                                    <li className="include">
                                        <p><i className="icofont-check-circled"></i>Unlimited updates</p>
                                    </li>
                                    <li className="exclude">
                                        <p><i className="icofont-close-circled"></i>Live support</p>
                                    </li>
                                </ul>
                                <a href="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>

    )
}

export default HomeProducts