import React, { useEffect, useState } from "react";
import Wrapper from "../../../../hoc/Wrapper/Wrapper";
import SubNavItem from "./SubNavItem";
import RouteLink from "../../../Routing/RouteLink";

const NavItem = (props) => {
    const[itemState,setItemState]=useState(props.item)
    const[tagState,setTagState]=useState(null)
    const[clickState,setClickState]=useState(false)
    const clickItem=(event)=>{
       // alert(window.screen.width)
        setClickState(true);
        props.click(event)
    }
   useEffect(()=>{
    setItemState({...props.item})
   },[props.item])
    useEffect(()=>{


        let   childs=null
        let haschildClass="nav-item"
        if(props.item.children && props.item.children.length>0)
        {
    
            haschildClass="nav-item has_dropdown"
            childs= props.item.children.map((item, index) => {
                return <SubNavItem item={item}  key={index} /> 
                })
    
        }
        const myTag= ( itemState.isExternal?      <li className={haschildClass}>
        <a className="nav-link" target="_blank" href={itemState.path}>{itemState.title}</a>
           </li>:  <li className={haschildClass}>
           <RouteLink href={itemState.path} click={clickItem} className="nav-link" >{itemState.title}</RouteLink>
            
              {
                  
                  childs?<Wrapper>
                      <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                      <div className={`sub_menu ${window.screen.width<600?'d-block':''}`} >
                      <ul>
                      {childs}
                      </ul>
                    </div>
                     
                      </Wrapper>:null
  
              }
          </li>)



setTagState(myTag)

    },[itemState,clickState])
   
    return (
        
              <>
             
               {tagState}
              </>

        
  


    )

}


export default NavItem