import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'



import BottomFooter from "../../components/site/template/Footer/Footer";
import TopWrapper from "../../components/site/template/TopWrapper/TopWrapper";
import SiteHome from "../../container/site/Home/Home";
import About from "../../container/site/About/About";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Languages from "../../components/site/template/TopWrapper/Languages";
import SiteProducts from "../../container/site/Products/Products";
import * as appConst from '../../store/consts'
import SiteProduct from "../../container/site/Product/Product";
import ShoppingCard from "../../container/shop/ShoppingCard/ShoppingCard";
import RialZibal from "../../container/site/Payments/RialZibal";
import ContactUs from "../../container/site/ContactUs/ContactUs";
import LanguagesModal from "../../components/site/template/Common/LanguagesModal";
const LayoutSite = () => {
    return (
        <Router>

        <div className="page_wrapper">


            <div id="preloader " className='d-none'>

                <div className="circle-border">
                    <div className="circle-core"></div>
                </div>
            </div>
           
           <Wrapper>
           <TopWrapper />
           

           <Routes>
          
               <Route path="/" element={<SiteHome/>} />
               <Route path="/about" element={<About/>} />
               <Route path="/Products/" element={<SiteProducts/>} />
               <Route path="/shopping-card/" element={<ShoppingCard/>} />
               <Route path="/Products/:id" element={<SiteProducts/>} />
               <Route path="/Product/:id" element={<SiteProduct/>} />
               <Route path="/admin/languages" element={<Languages/>} />
               <Route path="/payments/rial-zibal/:id" element={<RialZibal/>} />
               <Route path="/contact-us" element={<ContactUs/>} />

           </Routes>
           </Wrapper>
           


          

          



        </div>
        <BottomFooter />
        <LanguagesModal/>
        </Router>
    )
}

export default LayoutSite