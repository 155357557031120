import React, { useEffect, useState } from 'react'
import * as appConst from '../../../store/consts'
import { useSelector } from 'react-redux'

import Utils from "../../../modules/utils";
import { Link } from 'react-router-dom';
function ProductsList({ title, type }) {
    const utl = new Utils();

    const [productsState, setProductsState] = useState([])
    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates);
    const [showPackageState, setShowPackageState] = useState("مشاهده پکیج")


    useEffect(() => {

        loadData();
        try {
            tranlates.then(result => {
                setShowPackageState(utl.translateWord(result, "مشاهده پکیج"))


            })
        } catch {
            setShowPackageState("مشاهده پکیج")
        }



    }, [type])
    const loadData = async () => {
        const response = await fetch(`${appConst.api_url}/api/products-packages/type/${type}`)
        const result = await response.json();

        let packages = [];
        result.map((item) => {




            item.packages.map((itemb) => {


                try {
                    tranlates.then(result => {

                        itemb.name = utl.translateWord(result, itemb.name);
                        itemb.productName = utl.translateWord(result, item.name);

                    })
                } catch {
                    itemb.productName = item.name;
                }
                packages = [...packages, itemb]

            })

        })
        const chunkSize = 3;
        let chunks = [];
        for (let i = 0; i < packages.length; i += chunkSize) {
            const chunk = packages.slice(i, i + chunkSize);
            chunks = [...chunks, chunk];
            // do whatever
        }

        console.log("Chunk", chunks);
        setProductsState([...chunks])
    }


    const getRndInteger=()=> {
        const min=1;
        const max =6;
        return Math.floor(Math.random() * (max - min) ) + min;
      }


    return (
        <section className="row_am service_list_section">
            <div className="container">
                <div className="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">

                    <h2>{title} </h2>

                    <p></p>
                </div>


                {
                    productsState.map((ind) => {
                        return <div className="service_list_inner aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">

                            {

                                ind.map((item) => {
                                    return <div className="s_list_card">
                                        <div className="icons">
                                            <img src={`/assets/images/service-${getRndInteger()}.svg`} alt="image" />
                                            <div className="dot_block">
                                                <span className="dot_anim"></span>
                                                <span className="dot_anim"></span>
                                                <span className="dot_anim"></span>
                                            </div>
                                        </div>
                                        <div className="inner_text">
                                            <h3>{item.productName}</h3>
                                            <p>
                                                {item.name}
                                            </p>
                                            <Link className="btn text_btn" to={`/product/${item.id}`}>{showPackageState} <i className="icofont-arrow-right"></i></Link>
                                        </div>
                                    </div>

                                })
                            }

                        </div>
                    })
                }





            </div>
        </section>
    )
}

export default ProductsList