import React from 'react'

function Contact1() {
    return (
        <section class="row_am contact_list_section">
            <div class="container">

                <div class="contact_list_inner aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">

                    <div class="c_list_card">
                        <div class="icons">
                            <img src="./assets/images/mail.png" alt="image" />
                            <div class="dot_block">
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                            </div>
                        </div>
                        <div class="inner_text">
                            <h3>Chat to sales</h3>
                            <p>Let’s discuss with our sales team
                            </p>
                            <a href="mailto:support@example.com" class="text_btn">example@gmail.com</a>
                        </div>
                    </div>

                    <div class="c_list_card">
                        <div class="icons">
                            <img src="./assets/images/location.png" alt="image" />
                            <div class="dot_block">
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                            </div>
                        </div>
                        <div class="inner_text">
                            <h3>Visit our office</h3>
                            <p>Reach us to our offcie and meest us
                            </p>
                            <a href="#" class="text_btn">5687, Business Avenue, New York, USA 5687 </a>
                        </div>
                    </div>

                    <div class="c_list_card">
                        <div class="icons">
                            <img src="./assets/images/phone.png" alt="image" />
                            <div class="dot_block">
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                            </div>
                        </div>
                        <div class="inner_text">
                            <h3>Call us</h3>
                            <p>Call us directly with sales team
                            </p>
                            <a href="tel:+1-900-1234567" class="text_btn">+1 (888) 553-46-11</a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Contact1