import React, { useEffect, useState } from "react";

import Wrapper from "../../../../hoc/Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { connect,useDispatch } from 'react-redux'
import RouteLink from "../../../Routing/RouteLink";

import {addChangeView} from '../../../../store/pathActions'

const SubNavItem = (props) => {

    
    const dispatch = useDispatch()
    const navigate = useNavigate(); 
    const [itemState, setItemState] = useState("window.location.pathname")
  useEffect(()=>{
 
         dispatch(addChangeView(itemState))
 
     },[itemState])
     const clickMenuItem = () => {
      
       setItemState(props.item.path)
         navigate(props.item.path)
 
     }
    return (
        
        <li className="">
            <RouteLink href={props.item.path} click={clickMenuItem}  >{props.item.title}</RouteLink>
            {/* <a className=""  onClick={clickMenuItem}>{props.item.title}</a> */}
           
        </li>


    )

}


export default SubNavItem