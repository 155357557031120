import React from 'react'

function AuthAlert({message,errors=[]}) {
  return (
    <div className="alert alert-danger text-right alert-dismissible fade show mx-3" role="alert">
  <strong>اخطار!</strong> {message}.
 <ul>
{errors.map((item)=>{

return <li>{item}</li>

})}
 </ul>
</div>
  )
}

export default AuthAlert