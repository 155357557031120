import React, { useEffect, useState } from 'react';
import RouteLink from '../../../Routing/RouteLink';
import { useDispatch, useSelector } from 'react-redux'

import { addChangeView, addChangeLang, addChangeTranslateArr } from '../../../../store/pathActions'
import { useNavigate } from "react-router-dom";
import Utils from "../../../../modules/utils";
import API from '../../../../modules/api'
import ReactHtmlParser from 'react-html-parser'; 

import * as appConsts from '../../../../store/consts'


function AuthContainer(props) {
  const utl = new Utils();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.lang)
  const tranlates = useSelector((state) => state.tranlates);
  const dispatch = useDispatch();
  const [homeState, setHomeState] = useState(
    {
      logo_url: "/assets/images/Logo.png",
  
  
    });
  const [myState, setMyState] = useState({
    title: props.title,
    dsc: props.dsc,
    btn_back_title: "بازگشت به صفحه اصلی",
    site_login:""

  })



  useEffect(() => {
    loadData();



  }, [])

  const onHomeclicked = (e) => {
    e.preventDefault();
    dispatch(addChangeView("/"))
    if (window.history.replaceState) {
      navigate("/")


    }
  }
  useEffect(() => {
    loadTransaltes();


  }, [])
  useEffect(() => {
    loadTransaltes();


  }, [tranlates])


  const loadTransaltes = () => {
    try {
      tranlates.then(result => {

        const obj = {
          title: utl.translateWord(result, props.title),
          dsc: utl.translateWord(result,props.dsc),
          btn_back_title: utl.translateWord(result, "بازگشت به صفحه اصلی"),
          site_login: utl.translateWord(result, homeState.site_login)

        }

        setMyState({ ...obj })


      })
    } catch {

    }
  }

  const loadData = () => {

    let arrNames = ["site-login-logo-attach-id","site_login"];



    let obj = { ...homeState };
    try {

      (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
        //console.log("TRE", result)
        if (result["site-login-logo-attach-id"] != null) {
          obj[`logo_url`] = `${appConsts.api_url}/api/attachments/file/${result["site-login-logo-attach-id"]}`;
        }
        if (result["site_login"] != null) {
          obj[`site_login`] =result["site_login"];
        }
console.log('obj', obj)

        setHomeState({ ...homeState, ...obj });
      });

    } catch (error) {

      //console.log("ERR", error)
    }



  }



  return (
    <section className="signup_section">

      <div className="top_part">
        <RouteLink href="/" className="back_btn" click={(event) => onHomeclicked(event)}><i className="icofont-arrow-left"></i> {myState.btn_back_title}</RouteLink>
      </div>

      <div className="profile_block sign-in">

        <div className="profile_side">
          <div className="top_side">
            <RouteLink href="/" click={(event) => onHomeclicked(event)}><img src={homeState.logo_url} alt="image" /></RouteLink>

            <div className="rating">
              <span><i className="icofont-star"></i></span>
              <span><i className="icofont-star"></i></span>
              <span><i className="icofont-star"></i></span>
              <span><i className="icofont-star"></i></span>
              <span><i className="icofont-star"></i></span>
            </div>
            <p>
              {ReactHtmlParser (homeState.site_login)}
              </p>

          </div>

        </div>

        <div className="signup_form">
          <div className="section_title">
            <h2>{myState.title}</h2>
            <p>{myState.dsc}</p>
          </div>
          {props.children}
        </div>
      </div>
    </section>
  )
}

export default AuthContainer