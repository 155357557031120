import React, { useEffect, useState } from "react";


import { connect, useDispatch,useSelector } from 'react-redux'
import API from "../../../../modules/api";
import Utils from "../../../../modules/utils";

function Languages(props) {
  const [langsState, setLangsState] = useState([])
  const lang = useSelector((state) => state.lang)

  const tranlates = useSelector((state) => state.tranlatesArr)
  const utl = new Utils();
  useEffect(() => {




    (new API()).sendGETWithoutToken('/api/langs', (result) => setLangsState(result))

}, [])
  return (
    <li className="nav-item has_dropdown">
      <a className={`nav-link ${window.screen.width<600?'d-none':''}`} ><img className="lang-image" src={`/assets/flags/${lang}.png`} alt="image" /> {utl.translateWord(tranlates,lang)}</a>
      <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
      <div className={`sub_menu ${window.screen.width<600?'d-block':''}`}>
        <ul>
          {

            langsState.map((item, index) => {
              return <li className="lang-item" key={item.id}><img className="lang-image" src={`/assets/flags/${item.name}.png`} alt="image" /> <a className="" onClick={() =>props.clicked(item)} >{utl.translateWord(tranlates, item.title)}</a></li>
            })

          }

        </ul>
      </div>


    </li>
  )
}

export default Languages