import React, { useEffect, useState } from "react";

import { addChangeLang } from '../../../../store/pathActions'

import { connect, useDispatch, useSelector } from 'react-redux'
import API from "../../../../modules/api";
import Utils from "../../../../modules/utils";
import './LanguagesModal.css'
function LanguagesModal() {
  const [langsState, setLangsState] = useState([])
  const [firstState, setFirstState] = useState("2")
  const lang = useSelector((state) => state.lang)
  const dispatch = useDispatch();
  const tranlates = useSelector((state) => state.tranlatesArr)
  const utl = new Utils();
  useEffect(() => {

    if (localStorage.getItem("first")) {
      setFirstState(localStorage.getItem("first"))
    }


    (new API()).sendGETWithoutToken('/api/langs', (result) => setLangsState(result))

  }, [])
  const handleClick = (item) => {
    document.body.classList.remove("rtl")
    document.body.classList.remove("ltr")
    document.body.classList.add(item.dir)
    localStorage.setItem("lang", item.name)
    localStorage.setItem("dir", item.dir)
    localStorage.setItem("first", "2")
    setFirstState("2")
    dispatch(addChangeLang(item.name))
  }
  return (
    <>
      {
        firstState == "1" && <>
          <div className="languages-modal">


            <div className="d-flex">
              {

                langsState.map((item, index) => {
                  return <div className="col-12 col-md-3 " key={item.title} onClick={() => handleClick(item)}>
                    <img className="lang-image" src={`/assets/flags/${item.name}.png`} alt="image" />

                    <h3 className="text-center">{utl.translateWord(tranlates, item.title)}</h3>
                  </div>
                })

              }

            </div>


          </div>
          <div className="languages-modal-backdrop">
          </div>
        </>
      }

    </>
  )
}

export default LanguagesModal