import React, { useEffect, useState, useRef, useCallback } from 'react'
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import {  useDispatch, useSelector } from 'react-redux'
import { addChangePageTitle } from '../../../store/pathActions'
import API from '../../../modules/api'
import ReactHtmlParser from 'react-html-parser'; 

import Utils from "../../../modules/utils";
import './About.css'
const About = (props) => {
      const utl = new Utils();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(addChangePageTitle("درباره ما"))

    })
    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates);
    const[textStates,setTextStates] = useState({
        about_us:""

    })

    
    useEffect(() => {
        loadData();



    }, [])
    useEffect(() => {


        loadData();



    }, [lang])
    useEffect(() => {
        loadData();



    }, [props])


    const loadData = () => {

        let arrNames = Object.keys(textStates).map((item)=> item);
        if(lang!=null && arrNames.indexOf(`about_us_${lang}`)==-1){
            arrNames=[...arrNames,`about_us_${lang}`];
        }
        let obj = { ...textStates };
        try {
            tranlates.then(result2 => {
      
            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)
                arrNames.forEach((item)=>{
                    if (result[item] != null) {
                       obj[item]=utl.translateWord(result2,result[item]);
                    }
                })
              

             
                console.log("WERTY", obj)
                setTextStates({ ...textStates, ...obj });
            });
        });
        } catch (error) {

            console.log("ERR", error)
        }



    }

    return (
        <Wrapper>
            {console.log(props)}

           <div className="row p-5 about">
           
{lang!=null && ReactHtmlParser (textStates[`about_us_${lang}`])}
           </div>
        </Wrapper>

    )


}

export default About