import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";


export default function RouteLink(props) {

    const navigate = useNavigate();
    const onClickLink=(event)=>{
      //alert('');
        event.preventDefault();
        navigate(props.href);
        if(props.click){
            props.click(event);
        }
        if(document.getElementsByClassName("navbar-collapse").length>0)
        document.getElementsByClassName("navbar-collapse")[0].classList.remove('show');
    }
  return (
    <a href="#" className={props.className} onClick={onClickLink}>{props.children}</a>
  )
}
