import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import Utils from "../../../../modules/utils";
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import RouteLink from "../../../Routing/RouteLink";

const TopPageWrapper=(props)=>{
 const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const page_title = useSelector((state) => state.page_title)
    const [myTitleState,setMyTitleState]=useState(page_title)

    useEffect(()=>{

      try {
        tranlates.then(result2 => {
          setMyTitleState((new Utils).translateWord(result2,page_title))
        });
      }
      catch{

      }

    },[lang,page_title])

return(

<div className="inner_page_block">
<Helmet>
          <title>{ myTitleState }</title>
        </Helmet>
<div className="banner_shapes">
  <div className="container">
    <span><img src="/assets/images/plus.svg" alt="image"/></span>
    <span><img src="/assets/images/polygon.svg" alt="image"/></span>
    <span><img src="/assets/images/round.svg" alt="image"/></span>
  </div>
</div>


<header className="fixed fix_style">

  <div className="container">
   
    <nav className="navbar navbar-expand-lg">
      <RouteLink className="navbar-brand" href="/">
        <img src={props.logo} alt="image"/>
      </RouteLink>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">
         
          <span className="toggle-wrap">
            <span className="toggle-bar"></span>
          </span>
        </span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
      {props.children}
      </div>
    </nav>
 </div>
 </header>
<div className="bread_crumb aos-init aos-animate" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
  <div className="container">
    <div className="bred_text">
      <h1>{myTitleState}</h1>
      <ul>
        <li><Link to="/"><FontAwesomeIcon icon={faHome}/></Link></li>
        <li><span>»</span></li>
        <li><Link to="">{myTitleState}</Link></li>
      </ul>
    </div>
  </div>
</div>

</div>

)

}

export default TopPageWrapper