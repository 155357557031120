import React, { useEffect, useState, useRef, useCallback } from 'react'
import Contact1 from '../../../components/site/ContactUs/Contact1'
import Contact2 from '../../../components/site/ContactUs/Contact2'
import Contact3 from '../../../components/site/ContactUs/Contact3'
import {  useDispatch, useSelector } from 'react-redux'
import { addChangePageTitle } from '../../../store/pathActions'
function ContactUs() {
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(addChangePageTitle("تماس با ما"))
    
        })
    return (
        <>



            <Contact1 />
            <Contact2 />
            <Contact3 />

        </>
    )
}

export default ContactUs