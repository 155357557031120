import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import HomeUniqFeatures from "../../../components/site/Home/HomeUniqFeatures/HomeUniqFeatures";
import HomeAnalyze from "../../../components/site/Home/HomeAnalyze/HomeAnalyze";
import HomeCollaborate from "../../../components/site/Home/HomeCollaborate/HomeCollaborate";
import HomeProducts from "../../../components/site/Home/HomeProducts/HomeProducts";
import HomeFAQ from "../../../components/site/Home/HomeFAQ/HomeFAQ";
import HomeLastCourse from "../../../components/site/Home/HomeLastCourse/HomeLastCourse";
import API from "../../../modules/api";
import Utils from "../../../modules/utils";
import * as appConsts from '../../../store/consts'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangePageTitle } from '../../../store/pathActions'
import ProductsList from "../../../components/site/Products/ProductsList";
const SiteProducts = (props) => {
    const utl = new Utils();
    const { id } = useParams();
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates);
    const [titleState, setTitleState] = useState({
        robot_title: "ربات",
        anddicator_title: "اندیکاتور"
    });

    useEffect(() => {
        loadTitle();

    })
    useEffect(() => {
        try {
            tranlates.then(result => {
                setTitleState({
                    robot_title: utl.translateWord(result, "ربات"),
                    anddicator_title: utl.translateWord(result, "اندیکاتور")
                })

            })
        } catch {

        }

    }, [lang])
    useEffect(() => {
        let url = `${appConsts.api_url}/api/products`;
        if (id)
            url = `${appConsts.api_url}/api/products/type/${id}`;
        //  alert("DD")
        loadTitle();

    }, [id])

    const loadTitle = () => {
        let temp = "محصولات";

        switch (id) {
            case "1":
                temp = `${temp}|ربات`
                break;
            case "2":
                temp = `${temp}|اندیکاتور`
                break;
            default:
                break;
        }

        dispatch(addChangePageTitle(temp))
    }
    return (

        <Wrapper>
            {console.log("PRD", id)}

            {id == null || id == 1 ? <ProductsList title={titleState.robot_title} type="1" /> : null}
            {id == null || id == 2 ? <ProductsList title={titleState.anddicator_title} type="2" /> : null}


        </Wrapper>
    )
}

export default SiteProducts